import React from "react";
import "twin.macro";
import { DotMatrix } from "../index";

const Exemptions = () => {
  return (
    <section>
      <div tw="bg-gray-50 overflow-hidden py-16 relative">
        <DotMatrix side="right" />
        <div tw="relative px-4 sm:px-6 lg:px-8">
          <div tw="mt-6 prose prose-lg text-gray-500 mx-auto">
            <h2>NHS Exemptions</h2>
            <p>
              You are entitled to an eye examination under the NHS if you fit
              one of these criteria:{" "}
            </p>
            <ul>
              <li>Children and students under the age of 19</li>
              <li>60 and over</li>
              <li>Diabetic</li>
              <li>
                Glaucoma sufferers and their immediate relatives over 40 years
                old
              </li>
              <li>
                In receipt of Income Support, Job Seekers Allowance, Working
                Family Tax Credit and Employment & Support Allowance
              </li>
              <li>Exemption certificate (HC2)</li>
              <li>Complex lenses requirement</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Exemptions;
