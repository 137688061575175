import React, { FormEventHandler, useState } from "react";
import tw, { TwStyle } from "twin.macro";
import { graphql, useStaticQuery } from "gatsby";
import { Listbox, Transition } from "@headlessui/react";
import { FaCircleNotch } from "react-icons/fa";
import { Button } from ".";
import { useModalContext } from "../../context/useModal";

const FORM_URL =
  "https://67vpxv060k.execute-api.us-east-1.amazonaws.com/Prod/submitForm";

const successText = "Thank You! We'll be in touch soon.";
const errorText =
  "Sorry, there was an error. Please refresh the page and try again.";

const query = graphql`
  query contactFormQuery {
    allStrapiBranchSettings(filter: { group: { name: { eq: "MKO" } } }) {
      edges {
        node {
          name
          secondaryName
          contactDetails {
            telephone
            email
          }
        }
      }
    }
  }
`;

interface contactFormProps {
  selectedBranch?: string;
  wrapperStyles?: TwStyle;
}

interface fieldAttributes {
  customerName: string;
  email: string;
  phone?: string;
  branch: string;
  message: string;
}

const inputStyles = tw`block focus:outline-none border border-gray-300 focus:border-denim-800 placeholder-gray-500 px-4 py-3 rounded-md shadow-sm w-full`;
const selectBoxStyles = tw`cursor-default relative w-full rounded-md border border-gray-300 pl-4 py-3 text-left focus:outline-none focus:ring-denim-200 focus:border-denim-300 transition ease-in-out duration-150`;

const ContactForm: React.FC<contactFormProps> = ({
  selectedBranch = "",
  wrapperStyles,
}) => {
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState<fieldAttributes>({
    customerName: "",
    email: "",
    phone: "",
    branch: selectedBranch,
    message: "",
  });

  const { allStrapiBranchSettings } = useStaticQuery(query);
  const { setOpen, open } = useModalContext();

  const branches = allStrapiBranchSettings.edges.map((a) => a.node);

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    const { customerName, email, branch, message, phone } = fields;
    const branchEmail =
      branch === "General" || !branch
        ? "admin@mko.co.uk"
        : branches.find((a) => a.name === branch).contactDetails.email;

    fetch(FORM_URL, {
      method: "POST",
      body: JSON.stringify({
        customerName,
        email,
        phone,
        message,
        branchEmail,
      }),
    })
      .then(() => {
        setSuccess(successText);
        setLoading(false);
      })
      .catch(() => {
        setSuccess(errorText);
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    setFields((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSelect = (branch: string) =>
    setFields((prev) => ({ ...prev, branch }));

  return (
    <>
      {loading && (
        <div tw="absolute top-0 left-0 rounded-lg z-40 text-5xl h-full w-full flex items-center justify-center bg-gray-200 opacity-50">
          <FaCircleNotch tw="animate-spin text-denim-800" />
        </div>
      )}
      <div css={[wrapperStyles]}>
        {!!success ? (
          <div>
            <p tw="font-semibold text-lg">{success}</p>
            {open && (
              <Button
                text="close"
                onClick={() => setOpen(false)}
                customStyles={tw`mt-4 bg-gray-200`}
              />
            )}
          </div>
        ) : (
          <form
            tw="grid grid-cols-1 gap-y-3 sm:gap-y-6 relative"
            onSubmit={handleSubmit}
          >
            <div>
              <Listbox
                as="div"
                tw="space-y-1"
                value={fields.branch}
                onChange={handleSelect}
              >
                {({ open }) => (
                  <div tw="relative">
                    <span tw="inline-block w-full rounded-md shadow-sm">
                      <Listbox.Button
                        css={[
                          selectBoxStyles,
                          fields.branch ? tw`text-gray-900` : tw`text-gray-500`,
                        ]}
                      >
                        <span tw="block truncate">
                          {fields.branch || "Branch"}
                        </span>
                        <span tw="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <svg
                            tw="h-6 w-6 text-gray-500"
                            viewBox="0 0 20 20"
                            fill="none"
                            stroke="currentColor"
                          >
                            <path
                              d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                      </Listbox.Button>
                    </span>

                    <Transition
                      show={open}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      tw="absolute mt-1 w-full rounded-md bg-white shadow-lg"
                    >
                      <Listbox.Options
                        static
                        tw="rounded-md py-1 text-base leading-6 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none"
                      >
                        {[{ name: "General" }, ...branches].map(
                          ({ name, secondaryName }) => (
                            <Listbox.Option key={name} value={name}>
                              {({ selected, active }) => (
                                <div
                                  css={[
                                    tw`cursor-default select-none relative py-1 sm:py-2 pl-8 pr-4`,
                                    active
                                      ? tw`text-white bg-denim-600`
                                      : tw`text-gray-900`,
                                  ]}
                                >
                                  <span
                                    css={[
                                      tw`block truncate sm:mt-0`,
                                      selected
                                        ? tw`font-semibold`
                                        : tw`font-normal`,
                                    ]}
                                  >
                                    {name}
                                  </span>
                                  {!!secondaryName && (
                                    <span tw="text-gray-400 text-xs block mt-0 sm:mt-2">
                                      {secondaryName}
                                    </span>
                                  )}
                                  {selected && (
                                    <span
                                      css={[
                                        tw`absolute inset-y-0 left-0 flex items-center pl-1.5`,
                                        active
                                          ? tw`text-white`
                                          : tw`text-denim-600`,
                                      ]}
                                    >
                                      <svg
                                        tw="h-5 w-5"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                      >
                                        <path
                                          fillRule="evenodd"
                                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                          clipRule="evenodd"
                                        />
                                      </svg>
                                    </span>
                                  )}
                                </div>
                              )}
                            </Listbox.Option>
                          )
                        )}
                      </Listbox.Options>
                    </Transition>
                  </div>
                )}
              </Listbox>
            </div>
            <div>
              <label htmlFor="customerName" tw="sr-only">
                Full name
              </label>
              <input
                onChange={handleChange}
                type="text"
                name="customerName"
                id="customerName"
                autoComplete="name"
                css={[inputStyles]}
                placeholder="Full name"
                required
              />
            </div>
            <div>
              <label htmlFor="email" tw="sr-only">
                Email
              </label>
              <input
                onChange={handleChange}
                name="email"
                type="email"
                autoComplete="email"
                css={[inputStyles]}
                placeholder="Enter your email"
                id="email"
                required
              />
            </div>
            <div>
              <label htmlFor="phone" tw="sr-only">
                Phone
              </label>
              <input
                onChange={handleChange}
                type="text"
                name="phone"
                id="phone"
                autoComplete="tel"
                css={[inputStyles]}
                placeholder="Phone"
              />
            </div>

            <div>
              <label htmlFor="message" tw="sr-only">
                Message
              </label>
              <textarea
                onChange={handleChange}
                id="message"
                name="message"
                rows={4}
                css={[inputStyles]}
                placeholder="Message"
                required
              ></textarea>
            </div>
            <div tw="flex justify-end">
              <Button text="Submit" type="submit" colour="primary" />
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default ContactForm;
