import React from "react";
import tw, { css } from "twin.macro";

interface blogContentProps {
  content: string;
  textWhite?: boolean;
}

const BlogContentBlock: React.FC<blogContentProps> = ({
  content,
  textWhite,
}) => {
  const proseStyles = css`
    & * {
      ${tw`prose-lg!`}
    }
    & strong {
      ${tw`font-bold`}
    }
    & p,
    & p > span,
    & blockquote,
    & blockquote > span {
      ${textWhite ? tw`text-gray-100!` : tw`text-gray-500!`}
    }
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      ${textWhite ? tw`text-gray-100!` : tw`text-gray-800!`}
    }
    & img {
      ${tw`w-full rounded-lg`}
    }
  `;
  return (
    <div
      dangerouslySetInnerHTML={{ __html: content }}
      className="strapi-html-content"
      css={[proseStyles]}
    />
  );
};

export default BlogContentBlock;
