import React from "react";
import "twin.macro";
import { Link } from "gatsby";
import readingTime from "reading-time";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const BlogCard = (props) => {
  const {
    content,
    author: { picture, name: authorName },
    category: { name: category },
    description,
    image,
    publishedAt,
    title,
    slug,
  } = props;
  const { text: postReadingTime } = readingTime(content);
  const publishDate = new Date(publishedAt);

  return (
    <div tw="flex flex-col rounded-lg shadow-lg overflow-hidden">
      <Link to={`/blog/${slug}`} tw="flex-shrink-0 -mb-2">
        <GatsbyImage
          tw="h-48 w-full"
          image={getImage(image.localFile)}
          alt={image.alternativeText}
          imgStyle={{
            borderTopLeftRadius: "0.5rem",
            borderTopRightRadius: "0.5rem",
          }}
        />
      </Link>
      <div tw="flex-1 bg-white p-6 flex flex-col justify-between">
        <div tw="flex-1">
          <p tw="font-medium text-denim-600 text-sm">{category}</p>
          <Link to={`/blog/${slug}`} tw="block mt-2">
            <p tw="font-semibold text-mkoGray-900 text-xl">{title}</p>
            <p tw="mt-3 text-base text-mkoGray-400">{description}</p>
          </Link>
        </div>
        <div tw="mt-6 flex items-center">
          <div tw="flex-shrink-0">
            <span tw="sr-only">{authorName}</span>
            <GatsbyImage
              tw="h-10 w-10 rounded-full"
              image={getImage(picture.localFile)}
              alt={`${authorName} profile`}
              imgStyle={{ borderRadius: "9999px" }}
            />
          </div>
          <div tw="ml-3">
            <p tw="text-sm font-medium text-gray-900">{authorName}</p>
            <div tw="flex space-x-1 text-sm text-gray-500">
              <time>
                {`${
                  months[publishDate.getMonth()]
                } ${publishDate.getDate()}, ${publishDate.getFullYear()}`}
              </time>
              <span aria-hidden="true"> &middot; </span>
              <span>{postReadingTime}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
