interface footerNavLinks {
  to: string;
  label: string;
}

interface footerNavEntries {
  heading: string;
  links: footerNavLinks[];
}

export const footerNavs: footerNavEntries[] = [
  {
    heading: "Eyecare",
    links: [
      { to: "/eye-examinations", label: "Eye Examinations" },
      { to: "/contact-lenses", label: "Contact Lenses" },
      { to: "/lenses", label: "Lenses" },
      { to: "/locations", label: "Book An Eye Exam" },
    ],
  },
  {
    heading: "Company",
    links: [
      { to: "/about", label: "About" },
      { to: "/blog", label: "Blog" },
      { to: "/careers", label: "Careers" },
      { to: "/contact-us", label: "Contact Us" },
      { to: "/audiology", label: "Audiology" },
      { to: "/privacy", label: "Privacy Policy" },
      { to: "/terms", label: "Terms & Conditions" },
    ],
  },
];

interface headerNavInterface {
  to?: string | undefined;
  label: string;
  subnavs?: {
    to: string;
    label: string;
  }[];
}

export const headerNavs: headerNavInterface[] = [
  {
    label: "Eyewear",
    subnavs: [
      { label: "Brands", to: "/brands" },
      { label: "Lenses", to: "/lenses" },
      { label: "Eyewear At MKO", to: "/eyewear" },
    ],
  },
  {
    label: "Eyecare",
    subnavs: [
      { label: "Optical Coherence Tomography (OCT)", to: "/oct" },
      { label: "Fundus Camera Technology", to: "/retinal-screening" },
      { label: "Contact Lenses", to: "/contact-lenses" },
      { label: "Eye Exams at MKO", to: "/eye-examinations" },
    ],
  },
  {
    label: "Locations",
  },
  { to: "/audiology", label: "Audiology" },
  { label: "Blog", to: "/blog" },
  { to: "/careers", label: "Careers" },
];
