import React, { FC } from "react";
import tw from "twin.macro";
import "twin.macro";
import { ContactForm } from ".";

const WrappedContactForm: FC<{ selectedBranch?: string }> = ({
  selectedBranch = "",
}) => {
  return (
    <div tw="max-w-sm md:max-w-screen-sm w-screen mx-auto bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all">
      <h2 tw="text-lg text-center font-bold mb-4">Make A Booking Enquiry</h2>
      <ContactForm selectedBranch={selectedBranch} />
    </div>
  );
};

export default WrappedContactForm;
