import React from "react";
import tw from "twin.macro";
import { Link } from "gatsby";
import { navItemProps } from "./Navbar";
import { ChevronRight } from "heroicons-react";

interface mobileNavItemProps extends navItemProps {
  setMobileSubnavOpen?: any;
  isSub?: boolean;
}

const MobileNavItem: React.FC<mobileNavItemProps> = ({
  label,
  to,
  subnavs,
  setMobileSubnavOpen,
  sublabel,
  isSub,
}) => {
  return (
    <div css={[tw`text-white text-base font-medium w-full px-4`, tw`py-4`]}>
      {!to ? (
        <div
          css={[tw`w-full flex justify-between`]}
          onClick={() => setMobileSubnavOpen({ label, subnavs, open: true })}
        >
          <span tw="font-semibold">{label}</span>
          <span>
            <ChevronRight />
          </span>
        </div>
      ) : (
        <>
          <Link css={[tw`block font-semibold`]} to={to}>
            {label}
            {!!sublabel && (
              <p tw="text-sm text-gray-400 whitespace-nowrap">{sublabel}</p>
            )}
          </Link>
        </>
      )}
    </div>
  );
};

export default MobileNavItem;
