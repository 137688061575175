import React from "react";
import tw, { TwStyle } from "twin.macro";
import { DotMatrix, BlogContentBlock, SEO } from "./index";

declare interface pageLayoutProps {
  title: string;
  subtitle?: string;
  intro?: string;
  content?: string;
  mainStyles?: TwStyle;
  flourish?: number;
}

const PageLayout: React.FC<pageLayoutProps> = ({
  content,
  intro,
  title,
  subtitle,
  children,
  mainStyles,
  flourish,
}) => {
  return (
    <>
      <SEO title={title} />
      <main css={[mainStyles]}>
        <section>
          <div tw="bg-gradient-to-r from-denim-800 to-denim-800 via-denim-900 text-white">
            <div tw="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
              <h1 tw="font-extrabold text-5xl text-white tracking-tight">
                {title}
              </h1>
              {!!subtitle && (
                <p tw="mt-3 text-denim-100 text-xl sm:mt-4">{subtitle}</p>
              )}
            </div>
          </div>
        </section>
        {!!content && (
          <section tw="relative py-16 bg-white overflow-hidden">
            {!!flourish && <DotMatrix side="left" positioning={tw`top-5`} />}
            {flourish === 2 && (
              <DotMatrix side="right" positioning={tw`top-1/2`} />
            )}
            <div tw="px-4 relative sm:px-6 lg:px-8">
              <div tw="max-w-prose mx-auto prose prose-lg">
                {!!intro && (
                  <div tw="text-xl text-gray-500 leading-8">{intro}</div>
                )}
                <BlogContentBlock content={content} />
              </div>
            </div>
          </section>
        )}
        {children}
      </main>
    </>
  );
};

export default PageLayout;
