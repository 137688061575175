import React, { useEffect, useRef } from "react";
import "twin.macro";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import Portal from "./Portal";

import { useModalContext } from "../../context/useModal";

const Modal = () => {
  const { open, setOpen, content, setContent } = useModalContext();
  const handleClose = () => {
    setContent(null);
    setOpen(false);
  };

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (!open) {
      setContent(null);
    } else {
      disableBodyScroll(wrapperRef.current, { hideBodyOverflow: true });
    }
  }, [open]);

  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks();
    };
  }, []);

  return open ? (
    <Portal>
      <div
        tw="fixed z-40 inset-0 overflow-y-auto"
        role="dialog"
        aria-modal="true"
      >
        <div
          tw="flex items-center justify-center h-screen pt-4 px-4 text-center"
          ref={wrapperRef}
        >
          <div
            onClick={handleClose}
            tw="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>

          {/* <!-- This element is to trick the browser into centering the modal contents. --> */}
          <span
            tw="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          {content}
        </div>
      </div>
    </Portal>
  ) : null;
};

export default Modal;
