import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react";
import { clearAllBodyScrollLocks } from "body-scroll-lock";

const ModalContext = createContext({});
const useModalContext = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error("useAppState must be used within a Provider");
  }
  return context;
};

const ModalContextProvider: FC = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState(null);

  useEffect(() => {
    if (!open) {
      clearAllBodyScrollLocks();
    }
    return () => clearAllBodyScrollLocks();
  }, [open]);

  return (
    <ModalContext.Provider value={{ open, setOpen, content, setContent }}>
      {children}
    </ModalContext.Provider>
  );
};

export { useModalContext, ModalContextProvider };
