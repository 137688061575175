import React from "react";
import tw from "twin.macro";

const CovidWarning: React.FC = () => {
  return (
    <section css={[tw`container mx-auto`]}>
      <div tw="px-8 py-16">
        <h2 tw="font-semibold text-center text-denim-800 text-xl tracking-wider uppercase">
          COVID-19 Update
        </h2>
        <p tw="font-medium text-center text-lg text-mkoGray-300">
          <span>
            Due to the current circumstances, the opening hours of our practices
            may change.
          </span>
          <br />
        </p>
        <p tw="font-medium text-center text-lg text-mkoGray-300">
          We would advise calling to make an appointment before you visit.
        </p>
      </div>
    </section>
  );
};

export default CovidWarning;
