import React from "react";
import tw, { TwStyle } from "twin.macro";
import { Transition, TransitionEvents } from "@headlessui/react";
import { ClassNames } from "@emotion/react";

interface transitionProps extends TransitionEvents {
  show: boolean;
  appear?: boolean;
  classNames?: TwStyle;
  action: "slide-left" | "slide-right" | "fade";
}

const getFadeProps = (css) => ({
  enter: css(tw`transform transition ease-out duration-200`),
  enterFrom: css(tw`opacity-0 translate-y-1`),
  enterTo: css(tw`opacity-100 translate-y-0`),
  leave: css(tw`transform transition ease-in duration-150`),
  leaveFrom: css(tw`opacity-100 translate-y-0`),
  leaveTo: css(tw`opacity-0 translate-y-1`),
});

const getSlideRightProps = (css) => ({
  enter: css(tw`transform transition ease-in-out duration-500 sm:duration-700`),
  enterFrom: css(tw`-translate-x-full`),
  enterTo: css(tw`translate-x-0`),
  leave: css(tw`transform transition ease-in-out duration-500 sm:duration-700`),
  leaveFrom: css(tw`translate-x-0`),
  leaveTo: css(tw`-translate-x-full`),
});

const getSlideLeftProps = (css) => ({
  enter: css(tw`transform transition ease-in-out duration-500 sm:duration-700`),
  enterFrom: css(tw`translate-x-full`),
  enterTo: css(tw`translate-x-0`),
  leave: css(tw`transform transition ease-in-out duration-500 sm:duration-700`),
  leaveFrom: css(tw`translate-x-0`),
  leaveTo: css(tw`translate-x-full`),
});

const effectProps = {
  fade: getFadeProps,
  "slide-right": getSlideRightProps,
  "slide-left": getSlideLeftProps,
};

const BasicTransition: React.FC<transitionProps> = ({
  show = false,
  appear = false,
  classNames = "",
  children,
  action = "fade",
  ...rest
}) => {
  return (
    <ClassNames>
      {({ css }) => (
        <Transition
          show={appear || show}
          appear={appear}
          {...effectProps[action](css)}
          css={[classNames]}
          {...rest}
        >
          {children}
        </Transition>
      )}
    </ClassNames>
  );
};

export default BasicTransition;
