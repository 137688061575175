// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-announcements-tsx": () => import("./../../../src/pages/announcements.tsx" /* webpackChunkName: "component---src-pages-announcements-tsx" */),
  "component---src-pages-audiology-tsx": () => import("./../../../src/pages/audiology.tsx" /* webpackChunkName: "component---src-pages-audiology-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-brands-tsx": () => import("./../../../src/pages/brands.tsx" /* webpackChunkName: "component---src-pages-brands-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-contact-lenses-tsx": () => import("./../../../src/pages/contact-lenses.tsx" /* webpackChunkName: "component---src-pages-contact-lenses-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-cookies-policy-tsx": () => import("./../../../src/pages/cookies-policy.tsx" /* webpackChunkName: "component---src-pages-cookies-policy-tsx" */),
  "component---src-pages-eye-examinations-tsx": () => import("./../../../src/pages/eye-examinations.tsx" /* webpackChunkName: "component---src-pages-eye-examinations-tsx" */),
  "component---src-pages-eyewear-tsx": () => import("./../../../src/pages/eyewear.tsx" /* webpackChunkName: "component---src-pages-eyewear-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lenses-tsx": () => import("./../../../src/pages/lenses.tsx" /* webpackChunkName: "component---src-pages-lenses-tsx" */),
  "component---src-pages-locations-tsx": () => import("./../../../src/pages/locations.tsx" /* webpackChunkName: "component---src-pages-locations-tsx" */),
  "component---src-pages-oct-tsx": () => import("./../../../src/pages/oct.tsx" /* webpackChunkName: "component---src-pages-oct-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-retinal-screening-tsx": () => import("./../../../src/pages/retinal-screening.tsx" /* webpackChunkName: "component---src-pages-retinal-screening-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-blogpost-template-tsx": () => import("./../../../src/templates/BlogpostTemplate.tsx" /* webpackChunkName: "component---src-templates-blogpost-template-tsx" */),
  "component---src-templates-job-template-tsx": () => import("./../../../src/templates/JobTemplate.tsx" /* webpackChunkName: "component---src-templates-job-template-tsx" */),
  "component---src-templates-locations-page-template-tsx": () => import("./../../../src/templates/LocationsPageTemplate.tsx" /* webpackChunkName: "component---src-templates-locations-page-template-tsx" */)
}

