import React from "react";
import tw from "twin.macro";
import { Button, WrappedContactForm } from "../index";
import { useModalContext } from "../../context/useModal";

interface OpeningHours {
  day: string;
  openingHour: number;
  openingMinute: number;
  closingHour: number;
  closingMinute: number;
  closed?: boolean;
}

interface locationsCardProps {
  headless?: boolean;
  name: string;
  secondaryName?: string;
  description?: string;
  googlePlacesId: string;
  contactDetails: {
    streetAddress: string;
    addressLocality: string;
    postalCode: string;
    telephone: string;
    email: string;
  };
  bookingUrl: string;
  openingHours: OpeningHours[];
  group: {
    socialLinks: {
      facebookUrl: string;
      instagramUrl: string;
      twitterUrl: string;
    };
  };
}

const amPamIser = (hour: number, minute: number) => {
  let amPam = "";
  let xHour;
  if (hour === 12) {
    amPam = "pm";
    xHour = 12;
  } else if (hour === 0) {
    amPam = "am";
    xHour = 12;
  } else if (hour > 12) {
    amPam = "pm";
    xHour = hour - 12;
  } else {
    amPam = "am";
    xHour = hour;
  }
  return `${xHour}${minute ? `.${minute}` : ""}${amPam}`;
};

const processOpeningHours = (array: any) => {
  return ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((shortDay) => {
    const {
      openingHour,
      openingMinute,
      closingHour,
      closingMinute,
      closed,
    } = array.find((a: OpeningHours) => a.day.includes(shortDay));
    if (closed) {
      return `${shortDay}: Closed`;
    }
    return `${shortDay}: ${amPamIser(openingHour, openingMinute)} - ${amPamIser(
      closingHour,
      closingMinute
    )}`;
  });
};

const LocationsCard: React.FC<locationsCardProps> = ({
  headless,
  name,
  secondaryName,
  description,
  googlePlacesId,
  bookingUrl,
  openingHours,
  contactDetails: {
    streetAddress,
    addressLocality,
    postalCode,
    telephone,
    email,
  },
  group: {
    socialLinks: { facebookUrl, instagramUrl, twitterUrl },
  },
}) => {
  const { setOpen, setContent } = useModalContext();

  const handleBookOnline = () => {
    setContent(<WrappedContactForm selectedBranch={name} />);
    setOpen(true);
  };

  return (
    <div tw="container pb-16 mx-auto">
      <div tw="mx-4 shadow-lg">
        {!headless && (
          <div tw="py-6 mt-2 text-center text-white rounded-t-lg bg-mkoGray-500">
            <h2 tw="text-3xl font-extrabold tracking-tight sm:text-4xl">
              {name}
            </h2>
            {!!secondaryName && <h3>{secondaryName}</h3>}
          </div>
        )}
        <div tw="flex flex-col rounded-b-lg shadow-lg md:flex-row">
          <div tw="flex-1">
            <div tw="w-full h-full">
              <iframe
                width="100%"
                height="100%"
                frameBorder="0"
                style={{ border: 0 }}
                src={`https://www.google.com/maps/embed/v1/place?q=place_id:${googlePlacesId}&key=${process.env.GATSBY_GOOGLE_API_KEY}`}
                allowFullScreen
                css={[
                  tw`rounded-tr-lg md:rounded-bl-lg md:rounded-tr-none`,
                  headless && tw`rounded-tl-lg`,
                ]}
              ></iframe>
            </div>
          </div>
          <div
            css={[
              tw`bg-white text-mkoGray-400`,
              tw`grid flex-1 grid-cols-1 gap-1 p-4 sm:grid-cols-2 sm:p-6 md:p-4 lg:p-6`,
              tw`rounded-bl-lg rounded-br-lg md:rounded-bl-none`,
              headless && tw`rounded-tr-lg`,
            ]}
          >
            <div>
              <h4 tw="py-2 text-sm font-semibold tracking-wider uppercase text-mkoGray-900">
                Address
              </h4>
              <div>
                <p>Martyn Kemp Opticians</p>
                <p>{streetAddress}</p>
                <p>{addressLocality}</p>
                <p>{postalCode}</p>
              </div>
            </div>
            <div>
              <h4 tw="py-2 text-sm font-semibold tracking-wider uppercase text-mkoGray-900">
                Get in touch
              </h4>
              <div>
                <p>{telephone}</p>
                <p tw="mt-1">
                  <a
                    href={`mailto:${email}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {email}
                  </a>
                </p>
                <div tw="flex mt-2 space-x-6 md:order-2">
                  <a
                    href={facebookUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    tw="text-mkoGray-400 hover:text-mkoGray-500"
                  >
                    <span tw="sr-only">Facebook</span>
                    <svg
                      tw="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </a>
                  <a
                    href={instagramUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    tw="text-mkoGray-400 hover:text-mkoGray-500"
                  >
                    <span tw="sr-only">Instagram</span>
                    <svg
                      tw="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </a>
                  <a
                    href={twitterUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    tw="text-mkoGray-400 hover:text-mkoGray-500"
                  >
                    <span tw="sr-only">Twitter</span>
                    <svg
                      tw="w-6 h-6"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                    >
                      <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div>
              <h4 tw="py-2 text-sm font-semibold tracking-wider uppercase text-mkoGray-900">
                Opening Hours
              </h4>
              <div>
                {processOpeningHours(openingHours).map((a) => (
                  <p key={Math.random()}>{a}</p>
                ))}
              </div>
            </div>
            <div>
              <h4 tw="py-2 text-sm font-semibold tracking-wider uppercase text-mkoGray-900">
                Appointments
              </h4>
              <p tw="hidden md:block">Call us on {telephone}, or</p>
              <Button
                text="Book by Phone"
                href={`tel:${telephone}`}
                colour="lighter"
                full
                customStyles={tw`md:hidden`}
              />
              {bookingUrl ? (
                <Button
                  text="Book Online"
                  colour="secondary"
                  href={bookingUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  full
                  customStyles={tw`mt-1`}
                />
              ) : (
                <Button
                  text="Book Online"
                  colour="secondary"
                  onClick={handleBookOnline}
                  full
                  customStyles={tw`mt-1`}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationsCard;
